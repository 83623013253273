<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import InputGroup from "@/components/newforms/input-group";
import TextAreaGroup from "@/components/newforms/text-area-group";
import SelectGroup from "@/components/newforms/select-group";
import MessageModal from "@/components/modals/message-modal";
import SaveBtn from "@/components/buttons/save-button";

export default {
    components: {
        Layout,
        PageHeader,
        InputGroup,
        TextAreaGroup,
        SelectGroup,
        MessageModal,
        SaveBtn
    },
    data() {
        // const id = this.$route.params.id;
        return {
            id: this.$route.params.id,
            title: "Payment Methods Edit",
            items: [
                {
                    text: "Payment Methods"
                },
                {
                    text: "Edit",
                },
            ],
            formData: {
                type:"",
                name:"",
                currency_code: "",
                description: "",
                status: 1,
            },
            status_options: [
                {"text": "Active", "value": 1},
                {"text": "Inactive", "value": 0},
            ],
            config_fileds: [],
            config_data: [],
            errors: []
        };
    },
    computed: {

    },
    watch: {

    },
    async created() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/setting/payment-method/${this.id}`, {
            headers: authHeader(),
        });
        if (result.status == 200) {
            const { data } = result.data;
            this.config_fileds = data.configurations.length > 0 ? data.configurations : [];
            for (const key of Object.keys(this.formData)) {
                if (Object.hasOwn(data, key)) {
                    this.formData[key] = data[key];
                }
            }

            if (this.config_fileds.length > 0) {
                for (const val of Object.values(this.config_fileds)) {
                    this.config_data[val.key] = val.value;
                }
            }
        }
    },
    methods: {
        async submitForm() {
            this.errors = {};
            let data = this.formData;
            let configdata = [];
            for (const key of Object.keys(this.config_data)) {
                configdata.push({key: key, value: this.config_data[key]});
            }
            data.config = configdata;

            await axios.put(`${process.env.VUE_APP_APIURL}/setting/payment-method/${this.id}`, data, {
                headers: authHeader(),
            })
            .then((e) => {
                if (e.status == 200) {
                    this.$refs.messageModal.showModal("Update successful!");
                }
            })
            .catch((e) => {
                if (e.response.status == 422) {
                    this.errors = e.response.data.errors;
                    for(const key of Object.keys(this.errors)) {
                        if (key.includes('config')) {
                            let num = key.split('.')[1];
                            this.errors[configdata[num].key] = this.errors[key];
                        }
                    }
                }
                console.log(e);
            });
        },
        returnBack() {
            console.log('zxc');
            this.$router.push({name:"Payment Methods"});
        }
    }
};
</script>

<template>
    <Layout>
        <MessageModal ref="messageModal" @CloseBtn="returnBack"></MessageModal>
        <PageHeader :title="title" :items="items"/>
        <div class="d-flex justify-content-end">
            <SaveBtn @click="submitForm" class="btn btn-orange mt-2 ml-auto">Save</SaveBtn>
            <button @click="returnBack" class="btn btn-danger mb-2 mt-2 ml-2">Cancel</button>
        </div>
        <b-card class="mb-2">
            <div class="row">
                <div class="col-12 col-md-6">
                    <InputGroup id="type" name="type" v-model="formData.type" label="Type" placeholder="Payment Method Type" :error="errors.type"/>
                    <InputGroup id="name" name="name" v-model="formData.name" label="Name" placeholder="Payment Method Name" class="mt-2" :error="errors.name"/>
                    <InputGroup id="currency_code" name="currency_code" v-model="formData.currency_code" label="Currency Code" placeholder="Currency Code" class="mt-2" :error="errors.currency_code"/>
                    <TextAreaGroup id="description" name="description" v-model="formData.description" label="Description" placeholder="Description" class="mt-2" :error="errors.description"/>
                    <SelectGroup id="status" name="status" v-model="formData.status" label="Status" placeholder="Status" class="mt-2" :options="status_options" :error="errors.status"/>
                    <template v-if="config_fileds.length != 0">
                        <InputGroup :id="config.key" :name="config.key" v-model="config_data[config.key]" :error="errors[config.key]" :label="config.title" :placeholder="config.title" v-for="(config) in config_fileds" :key="config.key" :class="config" class="mt-2"/>
                    </template>
                    
                </div>
            </div>
        </b-card>
    </Layout>
</template>