<script>
export default {
   

    data() {
        return {
        };
    },

    created() {

    },
    props: {
        id: {
            required: true,
            type: String,
        },
        name : {
            required: true,
            type: String,
        },
        placeholder: {
            type: String,
        },
        label: {
            required: true,
            type: String,
        },
        error: [],
        value: {}
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e);
        },
        // handleEnter() {
        //     if (this.onEnter) {
        //         this.onEnter();
        //     }
        // }
    }
};
</script>

<template>
    <div>
        <label for="input-live" class="mb-0">{{ label }}:</label>
        <b-form-textarea
          :id="id"
          :name="name"
          :value="value"
          @input="handleInput"
          :placeholder="placeholder"
          trim
        >
        </b-form-textarea
        >
        <b-form-invalid-feedback id="input-live-feedback" :state="false" v-if="error && error.length > 0">
            {{ error[0] }}
        </b-form-invalid-feedback>
    </div>
</template>
